import React, { useEffect, useState } from "react";

import axios from 'axios';
import styled from 'styled-components';
import Select from "react-select";
import CheckboxGroup  from "../CheckboxGroup";
import Checkbox from "../Checkbox";
import RadioGroup from "../RadioGroup";
import Radio  from "../Radiobox";
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import AgreePage from "../pages/product_agree";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import qs from "qs";

export default function Product_info(props){
var iconv = require('iconv-lite')
const [Rvalue, setValue] = useState("");
const [Rvalue2, setValue2] = useState("");
const [isChecked, setIsChecked] = useState(false);
const [isChecked2, setIsChecked2] = useState(false);
const [data, setData] = useState(null);


const [inputs, setInputs] = useState({
    userName: '',
    id_num1: '',
    userPhone1: '',
    userPhone2: '',
    userPhone3: '',
    app_id: 'm_0001',
    mode: 'hanto_auth_send',
    secu_key: 'bfc7edc9caeb8d52a06fdd7be9c02719',
    good_grp: '0010',


 });

const onChange = (e) => {//input에 name을 가진 요소의 value에 이벤트를 걸었다
    const { name, value } = e.target;
    setInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
}
const fetchData = async () => {
    try {
        //console.log("Rvalue ", Rvalue);
        //console.log("Rvalue2 ", Rvalue2);
        
        const transformedData = {
            ...inputs,
            id_num2: Rvalue,
            tongsinsa: Rvalue2,
            mozipname: props.mozipname,
            mozipcode: props.mozipcode,
        };


        const response = await fetch('/submit', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(transformedData),
        });

        if (!response.ok) {
            throw new Error('서버 응답이 실패했습니다.');
        }

        const remoteResult = await response.text();
        // 정규표현식을 사용하여 "00" 찾기
        const matchResult = remoteResult.match(/00/g);
        if (matchResult) {
            alert('정상 접수되었습니다.');
            inputs.userName = "";
            inputs.id_num1 = "";
            inputs.userPhone2 = "";
            inputs.userPhone3 = ""
            inputs.id_num2 = "";
            inputs.tongsinsa = "";
            window.location.href = '/product';

        } else {
            // "00"을 찾지 못한 경우의 처리 추가
            alert('접수실패 되었습니다.');
        }
        const result = await response.text();
        //console.log('서버 응답1:', result);
    } catch (error) {
      //console.error('오류 발생:', error);
    }
  };

function CheckPass(str){ //생년월일 체크
    let id_numReg =  /^\d+$/;
    return(id_numReg.test(str));
};

function CheckPassPhone(str){ //휴대전화 체크
    let phoneRegExp3 = /^([0-9]{3,4})$/;
    return(phoneRegExp3.test(str));
};
 
const handleSubmit = async (e) => { //로그인 유효성 검사
    e.preventDefault();
    if(props.mozipname === undefined){
        alert("상담사를 선택 해주세요.");
        props.handleButtonClick();
        return;
    }
    if(inputs.userName===""){
        alert("고객명을 입력해주세요.");
        return;
    }
    else if(inputs.id_num1===""){
        alert("생년월일을 입력해주세요.");
        return;
    }
    else if(CheckPass(inputs.id_num1) === false){
        alert("생년월일 숫자로 입력해주세요.");
        return;
    }
    else if(Rvalue === ""){
        alert("성별을 선택해주세요.");
        return; 
    }
    else if(Rvalue2 === ""){
        alert("통신사를 선택해주세요.");
        return;  
    }
    else if(inputs.userPhone2===""){
        alert("휴대전화를 입력해주세요.");
        return;
    }
    else if(CheckPassPhone(inputs.userPhone2) === false){
        alert("휴대전화 숫자로 입력해주세요.");
        return;
    }
    else if(inputs.userPhone3===""){
        alert("휴대전화를 입력해주세요.");
        return;
    }
    else if(isChecked ===false){
        alert("필수적동의사항을 동의하셔야 신청이 가능합니다.");
        return;
    }
    else if(isChecked2 ===false){
        alert("상품을 확인하시고 동의하셔야 신청이 가능합니다.");
        return;
    }
    else{

        await fetchData(); 
    }
};  

    return (
        
        <section class="pb-1" id="marketing">
   
        <div class="container">
        <div class="d-flex align-items-center mb-3"> <img class="me-sm-4 me-2" src="assets/img/manager/tick.png" width="35" alt="tick" />
                <p class="fw-medium text-secondary fs-6">대출신청</p>
        </div>

        <form class="mb-3" id="formElem" accept-charset="euc-kr">
        <input 
            type="hidden" 
            name="mozipname"
            value={props.mozipname}
            onChange={onChange}
        />
        <input 
            type="hidden" 
            name="mozipcode"
            value={props.mozipcode}
            onChange={onChange}
        />
        <p className="mb-1 text-secondary text-brand" align="left">
            <input 
                class="form-control" 
                name="userName" 
                placeholder="고객명" 
                aria-label="userName" 
                maxlength="20" 
               
                onChange={onChange}
              
            />
            <br />
            <input 
                class="form-control" 
                name="id_num1" 
                placeholder="생년월일 예)981111" 
                aria-label="id_num1" 
                maxlength="6" 
               
                onChange={onChange}
               
            /><br />
            
            <RadioGroup name="id_num2" value={Rvalue} onChange={setValue}>
            <Radio name="id_num2" value="1">남자</Radio> 
            <Radio name="id_num2" value="2">여자</Radio> 
            </RadioGroup>     
            
            <RadioGroup name="tongsinsa"value={Rvalue2} onChange={setValue2}>
            <Radio name="tongsinsa" value="1">SKT</Radio> 
            <Radio name="tongsinsa" value="2">KT</Radio> 
            <Radio name="tongsinsa" value="3">LG U+</Radio>
            <Radio name="tongsinsa" value="4">SKT(알뜰폰)</Radio>
            <Radio name="tongsinsa" value="5">KT(알뜰폰)</Radio>
            <Radio name="tongsinsa" value="6">LG U+(알뜰폰)</Radio>
            </RadioGroup>
            <br />
            
            <div style={{ display: "flex", flexDirection: "row" }}>
            <input 
                class="form-control" 
                type="userPhone1" 
                placeholder="010" 
                aria-label="userPhone1" 
                maxlength="3" 
                
                onChange={onChange}
               
            />
            <input
                class="form-control" 
                name="userPhone2" 
                aria-label="userPhone2" 
                maxlength="4" 
                
                onChange={onChange}
              
             />
             <input
                class="form-control" 
                name="userPhone3" 
                aria-label="userPhone3" 
                maxlength="4" 
               
                onChange={onChange}
               
            />
            
            </div><br />
            
            <Checkbox value={isChecked} name="prochk"  onChange={setIsChecked}> 개인정보 수집 및 활용동의[<a href="#" id="toggle" style={{font:'blue'}}>자세히 보기</a>]</Checkbox><br />
            <UncontrolledCollapse toggler="#toggle" className="m-0 p-0">
                    <Card>
                        <CardBody>
                            <AgreePage />
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            <Checkbox value={isChecked2} name="agreechk" onChange={setIsChecked2}> 신용대출 상품에 대한 상기의 설명을 확인하였습니다.</Checkbox>
            <button class="btn btn-warning fw-medium py-2 w-sm-100"  onClick={handleSubmit}>본인 인증 문자 받기</button>
            {data && <div>Data received: {JSON.stringify(data)}</div>}
        </p>
        </form>
        </div>
    </section>
    );
};