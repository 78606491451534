import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import styled from 'styled-components';
import Select from "react-select";
import CheckboxGroup  from "../CheckboxGroup";
import Checkbox from "../Checkbox";
import RadioGroup from "../RadioGroup";
import Radio  from "../Radiobox";

function Product_top(props){
  
  const [selectedOption, setSelectedOption] = useState({
    code: props.options2.code,
    id: props.options2.id,
    mobile: props.options2.mobile,
    urls: "",
    value: props.options2.value,
  });
  const [idnum, setIdnum] = React.useState([]);
  const [radio, setRadioOption] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [userName, setUserName] = useState("");
  const [userUrl, setUserUrl] = useState("");
  const selectRef = useRef(null);

 
  //console.log(props.options);
  
  useEffect(() => {
    setSelectedOption({
      code: props.options2.code,
      id: props.options2.id,
      mobile: props.options2.mobile,
      urls: props.options2.urls,
      value: props.options2.value,
    });
  }, [props.options2]);

  useEffect(() => {
    if (selectedOption === '') {
      // 선택된 옵션이 없을 때, Select 컴포넌트에 포커스를 주는 코드
     
      selectRef.current.focus();
    }

    const SelOption  = {
      mozipName: selectedOption?.value ?? "",
      mozipCode: selectedOption?.code ?? ""
    }
    props.getTextValue(SelOption)

  }, [selectedOption]);


  const handleSelectChange = (selectedOption) => {
    //console.log('Selected Option:', selectedOption);
    setSelectedOption(selectedOption);
    
    setUserName(selectedOption?.value ?? "");
    setUserUrl(selectedOption?.url ?? "");

    const SelOption  = {
      mozipName: selectedOption?.value ?? "",
      mozipCode: selectedOption?.code ?? ""
    }
    props.getTextValue(SelOption)

  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '300px',
    }),

  };
  const StyledSelect = styled(Select)`
  width: 300px;
  height: 40px;
  border: none;
  border-radius: 20px;
`;

const StyledText = styled(Select)`
font-size:1.0rem;
`;

const fontStyle = {
  fontSize: '8px',
};

const onClickCheck = () => {
  setIsChecked(!isChecked);
  //console.log(!isChecked);
};

  const newPosition = `background-position: top !important`;
  return (
    <section class="pt-5" id="marketing">
    <div className="bg-holder z-index--1 bottom-0 d-none d-lg-block" style={{backgroundImage:'url("assets/img/category/shape.png")',opacity:'0.5'}}></div>
    <div class="container">
          <h1 class="fw-bold fs-6 mb-3">살만한 대출 신청</h1>
          <p class="mb-6 text-secondary">대출모집상담사<span>

          <StyledSelect
            className="selectItem"
            name="mozipinfo"
            options={props.options}
            value={selectedOption}
            onChange={handleSelectChange}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.value}
            placeholder="상담사 선택"
            styles={customStyles}
            ref={selectRef}
        />
        
      </span></p>
      <div class="row">
        <div class="row border-top border-top-secondary pt-2">
          <div class="card-body ps-0">
            <p className="mb-1 text-secondary text-brand" align="left">
            <br className="d-none d-xl-block" style={fontStyle}/> 위 상담사<b>{selectedOption.value}</b>({selectedOption.mobile}/{selectedOption.id}/({selectedOption.code})은(는) (주)한국투자저축은행과 위탁계약을 체결한 대출모집법인(주)씨에이치파트너스에 소속되어 있으며,저축은행 중앙회에 등록된 대출상담사 입니다.
            <br className="d-none d-xl-block" style={fontStyle}/><b>{selectedOption.value} </b><br /> 위 상담사는한국투자저축은행만의 대출모집업무를 수행하며, 금융상품계약 체결권한이 없습니다.또한 고객에게 별도의 수수료를 요구하거나 수취하지 않습니다.
            <br className="d-none d-xl-block" style={fontStyle}/> 대출모집법인 확인방법 대출모집법인 포털사이트(www.loanconsultant.or.kr)
          </p>

          </div>
        </div>
        
        
      </div>
    </div>

  </section>

);
    //const [product, setProduct] = useState({});
    //const handleChange = (e) => {
      //  const {name, value} = e.target;
        //setProduct((product)=> ({...product, [name]:value}));
    //};
    //const handleSubmit = (e) => {};
    
    //return <section>
      //  <form onSubmit={handleSubmit}>
        //    <input type="text" name='name' value={product.name ?? ''} placeholder='고객명' required onChange={handleChange}/>
          //  <input type="text" name='id_num' value={product.id_num ?? ''} placeholder='생년월일 예) 981224' required onChange={handleChange}/>
            
        //</form>
    //</section>

    
}

export default Product_top;